import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import LayoutComponents from '../../../components/capital/Layouts/LayoutComponents';
export const _frontmatter = {
  "title": "Icon",
  "activeTab": "components",
  "componentId": "icon"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = LayoutComponents;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Please visit the `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/styles/iconography"
        }}>{`Iconoography`}</a></strong>{` page for more information on using the Icon component.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      